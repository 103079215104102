<template>
  <div class="header">
    <img class="logo" src="@/assets/svg/logo.svg" alt="Logo">
    <div class="play">
      <Button @click="openCasino" class="button">Казино</Button>
    </div>
    <a href="#casino" class="menu">О нас</a>
    <a href="#tokenomic" class="menu">Токеномика</a>
    <a href="#loadmap" class="menu">План развития</a>
    <a href="#faq" class="menu">Вопросы</a>
    <a href="#footer" class="menu">Контакты</a>
    <Button v-if="!walletAddress" @click="connectWallet" class="button">Подключить кошелек</Button>
    <Button v-if="walletAddress" @click="disconnectWallet" class="button">Отключить кошелек</Button>
    <div v-if="walletAddress">
      Подключен: {{ walletAddress }}
    </div>
  </div>
</template>

<script>
import tonConnectUI from '../plugins/tonconnect.js';

export default {
  data() {
    return {
      walletAddress: null,
      isConnected: false
    };
  },
  methods: {
    async connectWallet() {
      try {
        // Подключаем кошелек
        await tonConnectUI.connectWallet();
        const wallet = tonConnectUI.wallet;
        if (wallet) {
          this.walletAddress = wallet.account.address;
          this.isConnected = true;
        }
      } catch (error) {
      }
    },
    async disconnectWallet() {
      await tonConnectUI.disconnect();
      this.walletAddress = null;
      this.isConnected = false;
      console.log('Wallet disconnected');
    },
    openCasino() {
      window.open('https://disi.bet', '_blank');
    }
  },
  mounted() {
    // Проверяем, что wallet доступен, перед использованием
    if (tonConnectUI.wallet) {
      tonConnectUI.wallet.onChange((wallet) => {
        if (wallet) {
          this.walletAddress = wallet.account.address;
        } else {
          this.walletAddress = null;
        }
      });
    }
  }
}
</script>

<style lang="scss">
.logo {
  width: 140px;
  @media (max-width: 1280px) {
    width: 50%;
    max-width: 100px;
  }
}
.play {
  @media (max-width: 1280px) {
    display: none;
  }
}
.header {
  background-color: rgba(27,28,32,.48);
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  .menu {
    margin-left: 25px;
    color: rgb(195, 195, 195);
    cursor: pointer;
    &:hover {
      color: white;
    }
    @media (max-width: 1280px) {
      display: none;
    }
  }
}

.button {
  border-radius: 5px !important;
  margin-left: 25px;
  font-size: 1rem !important;
  color: white;
  @media (max-width: 1280px) {
    font-size: 0.8rem !important;
  }
  @media (max-width: 430px) {
    font-size: 0.6rem !important;
  }
}
</style>
