<template>
  <div id="offer" class="offer">
    <div class="left"><span class="purple">Мы сделаем предложение</span> от которого вы не сможете отказаться.
      <p> Начните получать прибыль от действующего онлайн казино вместе с <span class="text-white">$DIC</span> на
        <span class="text-white">The Open Network</span></p>
      <Button>
        <a href="https://docs.disi.bet" target="_blank" rel="noopener noreferrer">
          Документация
        </a>
      </Button>
      <Button class="p-button-outlined ml-3">
        Pre-sale
      </Button>
    </div>
    <div class="right">
      <img class="toncoins" src="@/assets/toncoins.png"/>
    </div>
  </div>
</template>
<style lang="scss">
.offer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
  .left {
    width: 50%;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 38px;
    @media (max-width: 600px) {
      width: 100%;
    }
    @media (max-width: 1100px) {
      width: 70%;
    }
  }
  .right {
    width: 50%;
    @media (max-width: 1100px) {
      width: 30%;
    }
    .toncoins {
      width: 100%;
      @media (max-width: 1100px) {
        max-width: 300px;
      }
    }
  }
}
</style>