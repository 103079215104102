<template>
  <div class="wrapper">
    <router-view />
  </div>
</template>

<script>
import "primeicons/primeicons.css";
import '@/assets/css/bootstrap.min.css'
import "@/assets/css/main.css";
import '@/assets/css/theme.scss'; // Импорт вашего кастомного файла стилей
export default ({
  components: {
  }
});
</script>

<style scoped>
.wrapper {
  height: 100%
}
</style>