<template>
  <div id="tokenomic" class="tokenomic">
    <div class="d-md-flex">
      <div class="left">
        <div class="asideLeft"
             @mouseover="setImage(5)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Private Sale</div>
            <div class="asideLeft-text">Линейный вестинг 8.33% каждый мес. (12 мес. +3)</div>
          </div>
          <div class="percent">
            20%
          </div>
          <div class="total">
            60M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(6)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Presale sale</div>
            <div class="asideLeft-text">2 мес. лок. Разблокировка 25% и далее каждые 3 мес. по 25% (9 мес. + 2)</div>
          </div>
          <div class="percent">
            2%
          </div>
          <div class="total">
            6M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(7)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Public sale</div>
            <div class="asideLeft-text">Линейный вестинг 8.33% каждый мес. (12 мес. +3)</div>
          </div>
          <div class="percent">
            8%
          </div>
          <div class="total">
            24M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(8)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Treasury</div>
            <div class="asideLeft-text">adsadasd</div>
          </div>
          <div class="percent">
            5%
          </div>
          <div class="total">
            15M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(9)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Marketing</div>
            <div class="asideLeft-text">10% анлок при TGE и 5% ежемесячный анлок (18 мес.)</div>
          </div>
          <div class="percent">
            12%
          </div>
          <div class="total">
            36M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(10)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Team</div>
            <div class="asideLeft-text">6 мес. лок.,5% Линейный вестинг на 20 мес. (20+6 мес.)</div>
          </div>
          <div class="percent">
            10%
          </div>
          <div class="total">
            30M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(1)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Airdrop & Community Incentives</div>
            <div class="asideLeft-text">50% анлок при TGE; 50% анлок после 6 мес. (6 мес.)</div>
          </div>
          <div class="percent">
            16%
          </div>
          <div class="total">
            48M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(2)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Staking & Farming <rewards></rewards></div>
            <div class="asideLeft-text">20% TGE; 4% Линейный вестинг</div>
          </div>
          <div class="percent">
            10%
          </div>
          <div class="total">
            30M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(3)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Liquidity (DEX and CEX)</div>
            <div class="asideLeft-text">20% Анлок при TGE и 10% ежемесячный анлок</div>
          </div>
          <div class="percent">
            11%
          </div>
          <div class="total">
            33M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(4)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Affiliate</div>
            <div class="asideLeft-text">10% анлок при TGE и 5% ежемесячный анлок (18 мес.)</div>
          </div>
          <div class="percent">
            6%
          </div>
          <div class="total">
            18M
          </div>
        </div>
      </div>
        <div class="right">
          <img :class="{'circle': true, 'd-block': activeImage === 0, 'd-none': activeImage !== 0}" src="@/assets/tokenomic-default.png">
          <img :class="{'circle': true, 'd-block': activeImage === 1, 'd-none': activeImage !== 1}" src="@/assets/tokenomic-1.png">
          <img :class="{'circle': true, 'd-block': activeImage === 2, 'd-none': activeImage !== 2}" src="@/assets/tokenomic-2.png">
          <img :class="{'circle': true, 'd-block': activeImage === 3, 'd-none': activeImage !== 3}" src="@/assets/tokenomic-3.png">
          <img :class="{'circle': true, 'd-block': activeImage === 4, 'd-none': activeImage !== 4}" src="@/assets/tokenomic-4.png">
          <img :class="{'circle': true, 'd-block': activeImage === 5, 'd-none': activeImage !== 5}" src="@/assets/tokenomic-5.png">
          <img :class="{'circle': true, 'd-block': activeImage === 6, 'd-none': activeImage !== 6}" src="@/assets/tokenomic-6.png">
          <img :class="{'circle': true, 'd-block': activeImage === 7, 'd-none': activeImage !== 7}" src="@/assets/tokenomic-7.png">
          <img :class="{'circle': true, 'd-block': activeImage === 8, 'd-none': activeImage !== 8}" src="@/assets/tokenomic-8.png">
          <img :class="{'circle': true, 'd-block': activeImage === 9, 'd-none': activeImage !== 9}" src="@/assets/tokenomic-9.png">
          <img :class="{'circle': true, 'd-block': activeImage === 10, 'd-none': activeImage !== 10}" src="@/assets/tokenomic-10.png">
        </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.tokenomic {
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 40px;
  align-self: stretch;
  .left {
    width: 50%;
    background: #24272c;
    color: white;
    font-weight: 600;
    border-radius: 15px 0 0 15px;
    margin-top: 10px;
    font-size: 38px;
    @media (max-width: 992px) {
      padding: 5px;
      width: 100%;
    }
    .asideLeft {
      background: #363942;
      cursor: pointer;
      display: flex;
      padding: 8px;
      margin: 10px;
      border-radius: 5px;
      &:hover {
        background: #7eae36;
      }
      &-header {
        font-size: 15px;
      }
      &-text {
        font-size: 12px;
        font-weight: 400;
      }
      .info {
        width: 80%;
      }
      .percent {
        width: 10%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .total {
        width: 10%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .right {
    width: 50%;
    background: #24272c;
    color: white;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    display: flex;
    border-radius: 0 15px 15px 0;
    margin-top: 10px;
    @media (max-width: 992px) {
      width: 100%;
    }
    .toncoins {
      width: 100%;
    }
  }
  .center {
    width: 100%;
    color: white;
    font-weight: 600;
    font-size: 38px;
    &-aside {
      display: flex;
      @media (max-width: 850px) {
        flex-wrap: wrap;
      }
    }
    .block {
      font-size: 20px;
      width: 20%;
      padding: 25px;
      background: #24272c;
      text-align: center;
      margin: 10px;
      border-radius: 15px;
      @media (max-width: 850px) {
        font-size: 28px;
        width: 100%;
      }
      p {
        font-size: 14px;
        font-weight: 200;
      }
    }
  }
  .circle {
    max-width: 555px;
    @media (max-width: 992px) {
      width: 100%;
      padding: 25px;
    }
    &:hover {
      display: block;
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      activeImage: 0 // Индекс активного изображения
    }
  },
  methods: {
    setImage(index) {
      this.activeImage = index;
    },
    resetImage() {
      this.activeImage = 0; // Сброс на изображение по умолчанию
    }
  }
}
</script>