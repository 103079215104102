import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import primevue from '@/plugins/vueprime';
import AOS from 'aos'
import 'aos/dist/aos.css'
// Создаем приложение Vue
const app = createApp(App);
AOS.init()
// Устанавливаем маршрутизатор и хранилище, затем монтируем приложение
app.use(store)
    .use(router)
    .use(primevue)
    .mount('#app');