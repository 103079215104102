<template>
  <div id="footer" class="footer">
      <div class="left">
        <img class="logo" src="@/assets/svg/logo.svg" alt="Logo">
      </div>
      <div class="right">
        <div class="mr-3">Поддержка</div>
        <div class="mr-3">Telegram RU</div>
        <div class="mr-3">Telegram EN</div>
        <div class="mr-3">Сообщество</div>
      </div>
      <hr>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
.logo {
  width: 80px;
  @media (max-width: 1280px) {
    width: 50%;
    max-width: 100px;
  }
}
.footer {
  padding: 25px;
  padding-top: 100px;
  max-width: 1360px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    display: block;
  }
  .left {
    width: 50%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .right {
    width: 50%;
    text-align: right;
    justify-content: right;
    display: flex;
    @media (max-width: 991px) {
      width: 100%;
      display: block;
    }
  }
}
</style>