// primevue.js
import PrimeVue from 'primevue/config';
import Lara from '@primevue/themes/lara';
import '@/assets/css/theme.scss'; // Импорт вашего кастомного файла стилей
import Button from 'primevue/button';
import Chart from 'primevue/chart';
import Timeline from 'primevue/timeline';
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';

// Import PrimeVue styles
import 'primeicons/primeicons.css';

export default function primevue(app) {
    app.use(PrimeVue, {
        theme: {
            preset: Lara,
            options: {
            }
        }
    });
    app.component('Button', Button);
    app.component('Chart', Chart);
    app.component('Timeline', Timeline);
    app.component('Accordion', Accordion);
    app.component('AccordionPanel', AccordionPanel);
    app.component('AccordionHeader', AccordionHeader);
    app.component('AccordionContent', AccordionContent);
}